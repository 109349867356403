<template>
    <el-tooltip v-if="isShowTooltip" class="item" effect="dark" placement="top">
        <div slot="content" style="max-width: 320px;">{{val}}</div>
        <div class="oneLine" @mouseenter="visibilityChange($event)">{{val}}</div>
    </el-tooltip>
    <div v-else class="oneLine" @mouseenter="visibilityChange($event)">{{val}}</div>
</template>

<script>
    export default {
        name: "CustTooltip",
        props: ['val'],
        data(){
            return {
                isShowTooltip: false
            }
        },
        mounted() {
        },
        methods : {
            visibilityChange(event){
                const ev = event.target;
                const ev_weight = ev.scrollWidth;
                const content_weight = ev.clientWidth;
                if (ev_weight > content_weight) {
                    this.isShowTooltip = true;
                } else {
                    this.isShowTooltip = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
  .oneLine {
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
  }
</style>